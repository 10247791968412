<template>
  <div class="temp">
    temp
  </div>
</template>

<script>
export default {
  name: 'temp'
}
</script>

<style lang="scss" scoped>
</style>